<template>
<section>
    <div class="page-header">
      <h3 class="page-title">
        Sections de la page "Accueil-Beekeeper"
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Accueil-Beekeeper</a></li>
          <li class="breadcrumb-item active" aria-current="page">Beekeeper</li>
        </ol>
      </nav>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row project-list-showcase">
                        <div class="col-md-12 d-flex justify-content-end">
                                <button class="mb-4 text-center btn" @click="pagePreview()"><i class="fa fa-eye"></i></button>
                            <!-- <router-link to="/preview">
                                <button class="mb-4 text-center btn"><i class="fa fa-eye"></i></button>
                            </router-link> -->
                            <button v-b-modal.addSection class="mb-4 text-right btn btn btn-gradient-primary btn-fw btn-secondary">Ajouter une section</button>
                        </div>
                        <b-modal id="addSection" title="Ajouter une nouvelle section" size="md" hide-footer>
                            <form class="forms-sample" @submit.prevent="addSection">
                                <b-form-group label="Nom"  label-for="sectionName">
                                    <b-form-input type="text" id="sectionName" v-model="sectionName" placeholder="Nom de la section"></b-form-input>
                                </b-form-group>

                                <!-- type entity -->
                                <b-form-group label="Type d'élément"  label-for="ulidcategory">
                                    <b-form-select id="entitytypes" v-model="selectedElement" :options="entitytypes" placeholder="Sélectionnez le type d'entité" size="lg" >
                                    </b-form-select>
                                </b-form-group>

                                <div class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-link btn-fw" @click="$bvModal.hide('addSection')">Annuler</button>
                                    <b-button type="submit" variant="success" class="mr-2">Ajouter</b-button>
                                </div>
                            </form>
                        </b-modal>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="mb-4">Liste des sections de la page</h4>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 project-grid draggable" v-for="(section, i) in sections" :key="i">
                                <div class="project-grid-inner row">
                                    <div class="col-md-12 col-lg-12 col-sm-12">
                                        <div class="d-flex align-items-start justify-content-between">
                                            <div class="wrapper">
                                                <h5 class="project-title" @click="sectionInfo(section)" style="cursor: pointer">{{section.title}}</h5>
                                                <p class="project-location"> <i class=" mdi mdi-math-compass "></i> {{section.blocks[0].element}}</p>
                                            </div>
                                            <div>
                                                <div class="badge badge-gradient-danger ml-auto pointer" @click="showAlertDeleteSection(section)"><i class="fa fa-trash"></i></div>
                                                <div class="badge badge-gradient-success ml-2 pointer"><i class="fa fa-pencil"></i></div>
                                            </div>
                                        </div>
                                        <p class="project-description">{{section.description}}</p>
                                        <div class="d-flex justify-content-between">
                                            <p v-if="section.blocks[0].entitytype" class="mb-2 font-weight-medium">Type: <span class="text-muted">Collection</span></p>
                                            <p v-else class="mb-2 font-weight-medium">Type: <span class="text-muted">éléments indépendants</span></p>
                                        </div>
                                    </div>
                                    <div class="col-md-12  mt-4">
                                        <sectionPreview :blocks="section.blocks" />
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>

                        <!-- Aperçu rapide de la page -->
                        <!-- <div class="col-md-5 d-flex">
                            <test-page-preview-vue v-if="this.ulidPage" :ulid="this.ulidPage"/>
                        </div> -->
                    </div>

                    <!-- Test vue Dragula -->
                    <!-- <div class="row">
                        <div class="col-md-12" v-dragula="colOne" drake="first">
                            <div class="card" v-for="item in colOne" :key="item.id">
                                <p>{{item.name}}</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import sectionPreview from '../../components/section-preview.vue'
// import Vue from 'vue':
// import { Vue2Dragula } from 'vue2-dragula'
import element from '../json/element.json'

// Vue.use(Vue2Dragula, {
//   logging: {
//     service: true // to only log methods in service (DragulaService)
//   }
// });

const axios= require('axios').default
export default {
    name: 'sectionPage',
    components: {
        // testPagePreviewVue
        sectionPreview
    },
    data () {
        return {
            ulidPage: '',
            sections: [],
            sectionName: '',
            // entitytypes: [
            //     { text: 'Texte', value: 'text', data: { "text": [] } },
            //     { text: 'Carousel', value: 'carousel', data: { "slides": [] } },
            //     { text: 'Card de produits', value: 'card', data: { "card": [] }},
            //     { text: 'Card Titre et description', value: 'card-title-description', data: { "card" : []}},
            //     { text: 'Bannière horizontale', value: 'horizontal-banner', data: {"horizontal-banner": []}}
            // ],
            entitytypes: element,
            selectedElement:'',
            colOne: [
                {name: 'Catherine'},
                {name: 'Kane'},
                {name: 'Michel'}
            ],
            colTwo: [
                'Keto Philip',
                'Jacob Stephen',
                'March Creg'
            ]
        }
    },
    watch :{
        selectedElement (value) {
            console.log("élément selectionné",value)
        }
    },
    methods: {
        async getSections (ulidPage) {
            await axios.get (`sections/pages/${ulidPage}`)
            .then (resSection => {
                console.log('mety',resSection)
                this.sections = resSection.data.result
            })
            .catch (errSection => {
                console.log('erreur',errSection)
            })
        },
        // Voir et modifier chaque section
        sectionInfo(section) {
            console.log({section})
            if (section.blocks[0].element =='carousel') {
                this.$router.push({
                    name: 'add-section-carousel',
                    params: {
                        ulid: section.ulid
                    }
                })
            }else if (section.blocks[0].element == 'card') {
                this.$router.push({
                    name: 'add-section-card',
                    params: {
                        ulid: section.ulid
                    }
                })
            } else if(section.blocks[0].element == 'text') {
                this.$router.push({
                    name: 'add-section-text',
                    params: {
                        ulid: section.ulid
                    }
                })
            } else if(section.blocks[0].element == 'card-title-description') {
                this.$router.push({
                    name: 'add-section-card-title-description',
                    params: {
                        ulid: section.ulid
                    }
                })
            } else if(section.blocks[0].element == 'horizontal-banner') {
                this.$router.push({
                    name: 'add-section-horizontal-banner',
                    params: {
                        ulid: section.ulid
                    }
                })
            } else if(section.blocks[0].element == 'card-icon-top') {
                this.$router.push({
                    name: 'add-section-card-icon-top',
                    params: {
                        ulid: section.ulid
                    }
                })
            } else {
                console.log('pas de section element associée')
            }
        },
        addSection() {
            axios.post( `sections`,
            {
                title: this.sectionName,
                ulidpage: this.$route.params.page,
                sectionorder: this.sections.length,
                blocks: [ this.selectedElement ]
            },
            {
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                },
            }
            )
            .then (resaddSection => {
                console.log(resaddSection)
                window.location.reload()
                })
            .catch ( ()=> console.log('error'))
        },
        deleteSection (section) {
            console.log(section.ulid)
            axios.delete(`sections/${section.ulid}`, {
                headers: {
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            .then(delSection => {
                console.log(delSection)
                window.location.reload()
                })
            .catch(errDelSection => console.log(errDelSection))
        },
        showAlertDeleteSection (section) {
            this.$swal({
                title: 'Êtes-vous sûr?',
                text: `Voulez-vous supprimer cette section '${section.title}'?`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, Supprimer',
                cancelButtonText: 'Annuler',
            })
            .then((result) => {
                if (result.value) {
                this.deleteSection(section)
                this.$swal(
                    'Supprimé!',
                    'La section a bien été supprimée',
                    'success'
                )
                }
             })
        },
        pagePreview () {
            this.$router.push ({
                name: 'page-preview',
                params: {
                    ulid: this.ulidPage
                }
            })
        },
        //Dragula
        onClick () {
            window.alert('click event')
        },
    },
    created () {
        // console.log('GLOBAL SERVICE: created')
        // let service = this.$dragula.$service

        // IMPORTANT!! setup empty named drakes matching
        // directive drake configs in template
        // otherwise may (currently) result in conflict

        // service.options('first', {
        // })

        // service.options('second', {
        // })

        // service.options('third', {
        // copy: true
        // })

        // console.log('GLOBAL SERVICE: ready')
    },
    mounted () {
        // console.log('page', this.page)
        this.ulidPage=this.$route.params.page
        // get Section of the page
        this.getSections (this.ulidPage)

        // get entitype
        axios.get (`entitytypes`)
        .then (resEntity => {
            console.log('entityType',resEntity)
            // this.entitytypes = resEntity.data.result
        })
        .catch (errSection => {
            console.log('entityType',errSection)
        })
    }
}
</script>

<style scoped>
.square {
    width: 100%;
    height: 100px;
  background-color: #555;
}
.btn-link{
    color: rgb(70, 70, 70);
}
.btn-secondary {
    background-color: none !important;
    border-color: none !important;
}
.draggable{
    cursor:grab
}
.draggable:active {
    cursor:grabbing !important
}
</style>